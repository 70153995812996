import axios from 'axios';
import { API_BASE_URL } from '../utils/baseurl';

export const postApi = (path, data) => {
  const tokens = localStorage.getItem('token');

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokens}`,
    },
  });
};

//file Upload...
export const fileUpload = (path, data) => {
  const tokens = localStorage.getItem('token');

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${tokens}`,
    },
  });
};

//file Upload with progress...
export const fileUploadwithprogress = (path, data,onUploadProgress,file) => {
  const tokens = localStorage.getItem('token');

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${tokens}`,
    },
     // Check if onUploadProgress is provided before adding it to the config
     ...(onUploadProgress && { onUploadProgress: (progressEvent) => onUploadProgress(progressEvent, file) }),
  
  });
};

export const putApi = (path, data) => {
  const tokens = localStorage.getItem('token');

  return axios.put(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokens}`,
    },
  });
};

export const postApiWithoutToken = (path, data) => {
  return axios.post(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const POST = (path, data) => {
  const token = localStorage.getItem('token');

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAuthApi = (path) => {
  const token = localStorage.getItem('token');

  return axios.get(API_BASE_URL + path, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getApi = (path, data) => {
  const token = localStorage.getItem('token');

  return axios.get(API_BASE_URL + path, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getWithoutDataApi = (path) => {
  const token = localStorage.getItem('token');

  return axios.get(API_BASE_URL + path, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDetailsById = (path) => {
  const token = localStorage.getItem('token');

  return axios.get(API_BASE_URL + path, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const patchApi = (path, data) => {
  const token = localStorage.getItem('token');

  return axios.patch(API_BASE_URL + path, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};
export const DeleteApi = (path) => {
  const token = localStorage.getItem('token');

  return axios.delete(API_BASE_URL + path, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const GET = (path) => {
  const token = localStorage.getItem('token');

  return axios.get(API_BASE_URL + path, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};